<template>
  <div>
    <!-- 页面一,二 -->
    <div class="GL_declareForm Gl_input">
      <Crumbs :title="'所在企业情况'"></Crumbs>
      <Form
        class="form"
        ref="formValidate"
        :model="formValidate"
        :label-width="0"
      >
        <Row>
          <Col class="col_unit" span="24">单位：万元、个、%</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              申报单位名称
            </Col>
            <Col class="m_col" span="20">
              <Input
                readonly
                v-model.trim="formValidate.companyInformation.companyName"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              所属行业
            </Col>
            <Col class="m_col" span="20">
              <Select
                v-model="formValidate.companyInformation.industry"
                disabled
              >
                <Option
                  :key="index"
                  v-for="(item, index) in tradeList"
                  :value="item.dataId"
                  >{{ item.dataValue }}</Option
                >
              </Select>
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              注册地址
            </Col>
            <Col class="m_col" span="20">
              <Input
                readonly
                v-model.trim="formValidate.companyInformation.registeredAddress"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              经营地址
            </Col>
            <Col class="m_col" span="20">
              <Input
                readonly
                v-model.trim="formValidate.companyInformation.businessAddress"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              社会信用代码
            </Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model.trim="formValidate.companyInformation.socialCreditCode"
                class="unit_input"
              />
            </Col>
            <Col class="m_col m_title" span="4">
              税务登记区
            </Col>
            <Col class="m_col" span="8">
              <Select
                v-model="formValidate.companyInformation.registeredArea"
                disabled
              >
                <Option
                  v-for="item in areaList"
                  :key="item.dataId"
                  :value="item.dataId"
                  >{{ item.dataValue }}</Option
                >
              </Select>
            </Col>
          </Row>
        </div>
        <!-- 基本情况 -->
        <Row>
          <Col span="24">基本情况</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">所有制性质</Col>
            <Col class="m_col" span="20">
              <div style="width:100%">
                <RadioGroup
                  class="RadioGroup"
                  size="large"
                  v-model="
                    formValidate.companyInformation.unitRegistrationTypeParentId
                  "
                >
                  <Radio
                    disabled
                    :key="index"
                    v-for="(item, index) in formValidate.companyType"
                    :label="item.dictId"
                    >{{ item.dictName }}</Radio
                  >
                </RadioGroup>
                <br />
                <div
                  v-show="childrenList.length > 0"
                  style="border-top:1px dashed #ccc;margin:5px 0"
                ></div>
                <RadioGroup
                  v-show="childrenList.length > 0"
                  class="RadioGroup"
                  size="large"
                  v-model="formValidate.companyInformation.allNature"
                >
                  <Radio
                    disabled
                    :key="index2"
                    v-for="(item2, index2) in childrenList"
                    :label="item2.dataId"
                    >{{ item2.dataValue }}</Radio
                  >
                </RadioGroup>
              </div>
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              <span>职工人数</span>
            </Col>
            <Col class="m_col" span="20">
              <Input
                readonly
                v-model="formValidate.companyInformation.numberOfEmployees"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              资产总额
            </Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.companyInformation.totalAssets"
                class="unit_input"
              />
            </Col>
            <Col class="m_col m_title" span="4">
              固定资产净值
            </Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.companyInformation.netWorth"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              资产负债率
            </Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.companyInformation.assetLiabilityRatio"
                class="unit_input"
              />
            </Col>
            <Col class="m_col m_title" span="4">
              信用评级
            </Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.companyInformation.bankCreditRating"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">企业是否属于</Col>
            <Col class="m_col m_padding" span="20">
              <CheckboxGroup size="large" v-model="CheckboxArray">
                <Checkbox disabled :label="1">上市企业</Checkbox>
                <Checkbox disabled :label="2">高新技术企业</Checkbox>
                <Checkbox disabled :label="4">专精特新企业</Checkbox>
              </CheckboxGroup>
            </Col>
          </Row>
        </div>
        <!-- 联系方式 -->
        <Row>
          <Col span="24">联系方式</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              人员
            </Col>
            <Col class="m_col m_title" span="5">
              姓名
            </Col>
            <Col class="m_col m_title" span="5">
              职务
            </Col>
            <Col class="m_col m_title" span="5">
              手机
            </Col>
            <Col class="m_col m_title" span="5">
              电子邮箱
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              企业负责人
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model.trim="formValidate.companyInformation.principalName"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model.trim="formValidate.companyInformation.principalPost"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidate.companyInformation.principalContactWay"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidate.companyInformation.principalEmail"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              申请联系人
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model.trim="formValidate.companyInformation.applyForName"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model.trim="formValidate.companyInformation.applyForPost"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidate.companyInformation.applyForContactWy"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidate.companyInformation.applyForContactEmail"
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
        <!-- 经营情况 -->
        <Row>
          <Col span="24">经营情况</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              近三年主要指标
            </Col>
            <Col class="m_col m_title" span="6">
              2022年
            </Col>
            <Col class="m_col m_title" span="6">
              2023年
            </Col>
            <Col class="m_col m_title" span="8">
              2024年前三季度
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              营业收入
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.companyInformation.operatingRevenueOne"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.companyInformation.operatingRevenueTwo"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.companyInformation.operatingRevenueThree"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              利润总额
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.companyInformation.totalProfitOne"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.companyInformation.totalProfitTwo"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.companyInformation.totalProfitThree"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              研发设计支出
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.companyInformation.inTheForeheadOne"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.companyInformation.inTheForeheadTwo"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.companyInformation.inTheForeheadThree"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              出口交货值
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.companyInformation.exportValueOne"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.companyInformation.exportValueTwo"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.companyInformation.exportValueThree"
                class="unit_input"
              />
            </Col>
          </Row>
        </div>

        <!-- 主要产品 -->
        <Row>
          <Col span="24">主要产品情况</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title icon_col" span="6">
              <span class="text_col">
                产品名称
              </span>
            </Col>
            <Col class="m_col m_title" span="6">
              上年度产能
            </Col>
            <Col class="m_col m_title" span="6">
              上年度产量
            </Col>
            <Col class="m_col m_title" span="6">
              上年度销售额
            </Col>
          </Row>
          <Row
            v-for="(item, index) in formValidate.companyContacts"
            :key="index"
            class="m_row"
          >
            <Col class="m_col icon_col" span="6">
              <Input
                readonly
                v-model.trim="item.productName"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input readonly v-model="item.capacity" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="item.previousYearOutput"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input readonly v-model="item.salesVolume" class="unit_input" />
            </Col>
          </Row>
        </div>
        <!-- 情况 -->
        <div class="m_item">
          <Row class="m_row">
            <Col class="m_col m_title" style="text-align: center" span="24">
              企业简介
            </Col>
          </Row>
          <Row class="m_row textarea_row">
            <Col span="24">
              <Input
                readonly
                v-model="formValidate.companyInformation.introduction"
                @on-change="
                  formValidate.companyInformation.introduction = $util.ToDBC(
                    formValidate.companyInformation.introduction,
                    $event
                  )
                "
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 8 }"
                placeholder="企业基本情况、主要产品、设计理念、技术特点、品牌建设、知识产权应用及保护制度、企业文化、履行社会责任等方面的情况。"
              ></Input>
            </Col>
          </Row>
        </div>
        <Row>
          <Col class="col_unit" span="24">单位：万元、平方米、个、%</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              设计创新中心名称
            </Col>
            <Col class="m_col" span="20">
              <Input
                readonly
                v-model.trim="
                  formValidate.industrialDesignCenter.designCenterName
                "
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
        <!-- 基本情况 -->
        <Row>
          <Col span="24">基本情况</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              成立时间
            </Col>
            <Col class="m_col" span="8">
              <DatePicker
                readonly
                type="date"
                :value="formValidate.industrialDesignCenter.establishedTime"
                placeholder="选择时间"
              ></DatePicker>
            </Col>
            <Col class="m_col m_title" span="4">
              <span>场所面积</span>
            </Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.industrialDesignCenter.siteArea"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              资产总额
            </Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.industrialDesignCenter.propertyTotal"
                class="unit_input"
              />
            </Col>
            <Col class="m_col m_title" span="4">
              <span>职工人数</span>
            </Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.industrialDesignCenter.employeeCount"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              运营模式
            </Col>
            <Col class="m_col m_padding" span="20">
              <RadioGroup
                size="large"
                v-model="formValidate.industrialDesignCenter.operationPattern"
              >
                <Radio disabled label="1">独立核算</Radio>
                <Radio disabled label="2">非独立核算</Radio>
              </RadioGroup>
            </Col>
          </Row>
        </div>
        <!-- 管理人员 -->
        <Row>
          <Col span="24">管理人员</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              人员
            </Col>
            <Col class="m_col m_title" span="5">
              姓名
            </Col>
            <Col class="m_col m_title" span="5">
              职务
            </Col>
            <Col class="m_col m_title" span="5">
              联系手机
            </Col>
            <Col class="m_col m_title" span="5">
              电子邮箱
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              负责人
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model.trim="formValidate.industrialDesignCenter.principalName"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model.trim="formValidate.industrialDesignCenter.principalPost"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidate.industrialDesignCenter.principalPhone"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidate.industrialDesignCenter.principalEmail"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              联系人
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model.trim="formValidate.industrialDesignCenter.contactName"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model.trim="formValidate.industrialDesignCenter.contactPost"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidate.industrialDesignCenter.contactPhone"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidate.industrialDesignCenter.contactEmail"
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
        <!-- 专业人员 -->
        <Row>
          <Col span="24">专业人员</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              设计创新团队人数
            </Col>
            <Col class="m_col" span="20">
              <Input
                readonly
                v-model="formValidate.industrialDesignCenter.businessCount"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              <span>其中：设计相关学科本科及以上学历人数</span>
            </Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.industrialDesignCenter.educationDutyCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col m_title" span="4">
              <span>占设计团队比例</span>
            </Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.industrialDesignCenter.educationDuty"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              <span>具有设计相关学科专业技术职称（职业资格）的人数</span>
            </Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.industrialDesignCenter.technicianCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col m_title" span="4">
              <span>占设计团队比例</span>
            </Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="
                  formValidate.industrialDesignCenter.technicianProportion
                "
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
        <!-- 硬件条件 -->
        <Row>
          <Col span="24">硬件设备</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title icon_col" span="8">
              <Icon class="icon" type="ios-add-circle-outline" />
              <span class="text_col">
                仪器设备名称
              </span>
            </Col>
            <Col class="m_col m_title" span="8">
              台（套）数
            </Col>
            <Col class="m_col m_title" span="8">
              价值
            </Col>
          </Row>
          <Row
            type="flex"
            v-for="(item, index) in formValidate.resourceAndPerfInfo
              .hardwareFacilityList"
            :key="index"
            class="m_row"
          >
            <Col class="m_col icon_col" span="8">
              <Icon class="icon" type="ios-remove-circle-outline" />
              <Input
                readonly
                v-model.trim="item.apparatusName"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="8">
              <Input readonly v-model="item.numberOfUnits" class="unit_input" />
            </Col>
            <Col class="m_col" span="8">
              <Input readonly v-model="item.cost" class="unit_input" />
            </Col>
          </Row>
        </div>
        <!-- 软件条件 -->
        <Row>
          <Col span="24">软件条件</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title icon_col" span="8">
              <Icon class="icon" type="ios-add-circle-outline" />
              <span class="text_col">
                软件名称
              </span>
            </Col>
            <Col class="m_col m_title" span="8">
              数量（套）
            </Col>
            <Col class="m_col m_title" span="8">
              价值
            </Col>
          </Row>
          <Row
            type="flex"
            v-for="(item, index) in formValidate.resourceAndPerfInfo
              .softwareList"
            :key="index"
            class="m_row"
          >
            <Col class="m_col icon_col" span="8">
              <Icon class="icon" type="ios-remove-circle-outline" />
              <Input
                readonly
                v-model.trim="item.softwareName"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="8">
              <Input readonly v-model="item.quantity" class="unit_input" />
            </Col>
            <Col class="m_col" span="8">
              <Input readonly v-model="item.cost" class="unit_input" />
            </Col>
          </Row>
        </div>
        <!-- 近三年指标 -->
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">
              近三年主要指标
            </Col>
            <Col class="m_col m_title" span="6">
              2022年
            </Col>
            <Col class="m_col m_title" span="6">
              2023年
            </Col>
            <Col class="m_col m_title" span="6">
              2024年前三季度
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">
              设计投入总额
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[0].inputTotal"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[1].inputTotal"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[2].inputTotal"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">
              占企业设计研发支出比重
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[0].expenseProportion"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[1].expenseProportion"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[2].expenseProportion"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">
              其中：设计人员经费支出
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[0].expenditureExpend"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[1].expenditureExpend"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[2].expenditureExpend"
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col
              style="justify-content: flex-start;"
              class="m_col m_title"
              span="6"
            >
              承担设计项目数
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[0].assumeProjectCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[1].assumeProjectCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[2].assumeProjectCount"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col
              class="m_col m_title"
              style="justify-content: flex-start;"
              span="6"
            >
              其中：完成项目数
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[0].finishProjectCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[1].finishProjectCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[2].finishProjectCount"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col
              class="m_col m_title"
              style="justify-content: flex-start;"
              span="6"
            >
              <span style="margin-left:3em"></span>产业化项目数
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  formValidate.inputInfoList[0].industrializationProjectCount
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  formValidate.inputInfoList[1].industrializationProjectCount
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  formValidate.inputInfoList[2].industrializationProjectCount
                "
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col
              style="justify-content: flex-start;"
              class="m_col m_title"
              span="6"
            >
              专利数
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[0].patentLicensingCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[1].patentLicensingCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[2].patentLicensingCount"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col
              class="m_col m_title"
              style="justify-content: flex-start;"
              span="6"
            >
              其中：发明专利(授权数)
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[0].inventPatentCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[1].inventPatentCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[2].inventPatentCount"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col
              class="m_col m_title"
              style="justify-content: flex-start;"
              span="6"
            >
              <span style="margin-left:3em"></span>实用新型(授权数)
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[0].practicalPatentCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[1].practicalPatentCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[2].practicalPatentCount"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col
              class="m_col m_title"
              style="justify-content: flex-start;"
              span="6"
            >
              <span style="margin-left:3em"></span>外观设计(授权数)
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[0].appearancePatentCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[1].appearancePatentCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[2].appearancePatentCount"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col
              style="justify-content: flex-start;"
              class="m_col m_title"
              span="6"
            >
              版权数（经登记数）
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[0].softPatentCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[1].softPatentCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[2].softPatentCount"
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col
              style="justify-content: flex-start;"
              class="m_col m_title"
              span="6"
            >
              设计标准制定数
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[0].designStandardCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[1].designStandardCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[2].designStandardCount"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col
              style="justify-content: flex-start;"
              class="m_col m_title"
              span="6"
            >
              其中：国际或国家标准
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[0].nationalStandardCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[1].nationalStandardCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[2].nationalStandardCount"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col
              class="m_col m_title"
              style="justify-content: flex-start;"
              span="6"
            >
              <span style="margin-left:3em"></span>行业标准
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[0].industryStandardCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[1].industryStandardCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[2].industryStandardCount"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col
              class="m_col m_title"
              style="justify-content: flex-start;"
              span="6"
            >
              <span style="margin-left:3em"></span>地方或团体标准
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[0].localStandardCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[1].localStandardCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.inputInfoList[2].localStandardCount"
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
        <!-- 近三年设计成果获奖情况 -->
        <Row>
          <Col span="24">近三年设计成果获奖情况</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title icon_col" span="6">
              <Icon class="icon" type="ios-add-circle-outline" />
              <span class="text_col">
                获奖作品
              </span>
            </Col>
            <Col class="m_col m_title" span="6">
              奖项名称
            </Col>
            <Col class="m_col m_title" span="6">
              获得时间
            </Col>
            <Col class="m_col m_title" span="6">
              授奖部门(或机构)
            </Col>
          </Row>
          <Row
            type="flex"
            v-for="(item, index) in formValidate.resourceAndPerfInfo
              .awardsInfoList"
            :key="index"
            class="m_row"
          >
            <Col class="m_col icon_col" span="6">
              <Icon class="icon" type="ios-remove-circle-outline" />
              <Input
                readonly
                v-model.trim="item.awardsWorks"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model.trim="item.awardsName"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <DatePicker
                :value="item.obtainTime"
                readonly
                type="date"
                placeholder="选择时间"
                style="width: 200px"
              >
              </DatePicker>
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model.trim="item.authorizationDepartment"
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
        <!-- 近三年开展公共服务情况 -->
        <Row>
          <Col span="24">近三年开展公共服务情况</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title icon_col" span="6">
              <Icon class="icon" type="ios-add-circle-outline" />
              <span class="text_col">
                服务类型
              </span>
            </Col>
            <Col class="m_col m_title" span="6">
              项目名称
            </Col>
            <Col class="m_col m_title" span="6">
              开展时间
            </Col>
            <Col class="m_col m_title" span="6">
              组织部门
            </Col>
          </Row>
          <Row
            v-for="(item, index) in formValidate.resourceAndPerfInfo
              .serviceList"
            :key="index"
            class="m_row"
          >
            <Col class="m_col icon_col" span="6">
              <Icon class="icon" type="ios-remove-circle-outline" />
              <Input
                readonly
                v-model.trim="item.serviceType"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input readonly v-model="item.serviceName" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
              <DatePicker
                :value="item.startTime"
                readonly
                type="date"
                placeholder="选择时间"
                style="width: 200px"
              ></DatePicker>
            </Col>
            <Col class="m_col" span="6">
              <Input readonly v-model="item.department" class="unit_input" />
            </Col>
          </Row>
        </div>
        <!-- 近三年主要设计成果产业化情况 -->
        <Row>
          <Col span="24">近三年主要设计成果产业化情况</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title icon_col" span="6">
              <Icon class="icon" type="ios-add-circle-outline" />
              <span class="text_col">
                项目名称
              </span>
            </Col>
            <Col class="m_col m_title" span="5">
              客户企业
            </Col>
            <Col class="m_col m_title" span="5">
              完成时间
            </Col>
            <Col class="m_col m_title" span="8">
              产业化效果
            </Col>
          </Row>
          <Row
            type="flex"
            class="m_row"
            v-for="(item, index) in formValidate.resourceAndPerfInfo
              .designResultList"
            :key="index"
          >
            <Col class="m_col icon_col" span="6">
              <Icon class="icon" type="ios-remove-circle-outline" />
              <Input
                readonly
                v-model.trim="item.projectName"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model.trim="item.clientCompany"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <!-- <Input
              readonly v-model="item.time" class="unit_input" /> -->
              <DatePicker
                :value="item.finishDeliveryTime"
                readonly
                type="date"
                placeholder="选择时间"
                style="width: 200px"
              >
              </DatePicker>
            </Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                type="textarea"
                v-model="item.industrializationResult"
                @on-change="
                  item.industrializationResult = $util.ToDBC(
                    item.industrializationResult,
                    $event
                  )
                "
                class="unit_input"
                placeholder="简述：项目内容、设计理念、设计创新点和技术水平情况，形成的新产品，为企业带来经济效益和社会效益情况等内容。"
                :autosize="{ minRows: 3, maxRows: 8 }"
              />
            </Col>
          </Row>
        </div>
        <!-- 牵头或参与完成国家、本市重大设计创新项目情况 -->
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="24">
              牵头或参与完成国家、本市重大设计创新项目情况
            </Col>
          </Row>
          <Row class="m_row textarea_row">
            <Col span="24">
              <Input
                readonly
                v-model="formValidate.statusQuoPlan.nationalMajorProgramInfo"
                @on-change="
                  formValidate.statusQuoPlan.nationalMajorProgramInfo = $util.ToDBC(
                    formValidate.statusQuoPlan.nationalMajorProgramInfo,
                    $event
                  )
                "
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 8 }"
                placeholder="近三年牵头或参与完成国家、本市重大研发、文化创意、重点工程、高质量发展产业项目等设计创新工作情况。"
              ></Input>
            </Col>
          </Row>
        </div>
        <!-- 组织或参与国家、本市重要设计类活动情况 -->
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="24">
              组织或参与国家、本市重要设计类活动情况
            </Col>
          </Row>
          <Row class="m_row textarea_row">
            <Col span="24">
              <Input
                readonly
                v-model="formValidate.statusQuoPlan.activitySituation"
                @on-change="
                  formValidate.statusQuoPlan.activitySituation = $util.ToDBC(
                    formValidate.statusQuoPlan.activitySituation,
                    $event
                  )
                "
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 8 }"
                placeholder="近三年牵头或参与设计创新重要活动，包括国家、本市重要设计类活动情况，承担省部级设计创新课题研究，与中小企业开展设计创新项目合作、为中小企业提供设计创新咨询服务，获得省部级示范认定等情况。"
              ></Input>
            </Col>
          </Row>
        </div>
        <!-- 发展情况说明 -->
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="24">
              发展情况说明
            </Col>
          </Row>
          <Row class="m_row textarea_row">
            <Col span="24">
              <Input
                readonly
                v-model="formValidate.statusQuoPlan.operationInfo"
                @on-change="
                  formValidate.statusQuoPlan.operationInfo = $util.ToDBC(
                    formValidate.statusQuoPlan.operationInfo,
                    $event
                  )
                "
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 8 }"
                placeholder="重点介绍设计创新中心发展经历、组织体系、团队建设、运营模式、主要业绩、管理制度、产学研合作情况，以及必要的佐证材料。另外，企业是否建立技术中心、研发中心，如有，阐述清楚其与设计创新中心的关系情况。（可另附页）"
              ></Input>
            </Col>
          </Row>
        </div>
        <!-- 中心今后三年目标与规划情况 -->
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="24">
              中心今后三年目标与规划情况
            </Col>
          </Row>
          <Row class="m_row textarea_row">
            <Col span="24">
              <Input
                readonly
                v-model="formValidate.statusQuoPlan.targetAndPlan"
                @on-change="
                  formValidate.statusQuoPlan.targetAndPlan = $util.ToDBC(
                    formValidate.statusQuoPlan.targetAndPlan,
                    $event
                  )
                "
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 8 }"
                placeholder="重点是中心今后三年创新建设、有效投入、设计成果等主要指标，以及组织体系建设、运营模式创新、团队建设、履行设计创新公共服务平台职责等规划和措施等情况。"
              ></Input>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
    <!-- 页面五 -->
    <div class="personnel GL_declareForm Gl_input">
      <Form
        class="form"
        ref="formValidateFive"
        :model="formValidateFive"
        :label-width="0"
      >
        <!-- 工业设计团队带头人及主要成员情况 -->
        <!-- 基本情况 -->
        <div
          class="forItem"
          :key="index"
          v-for="(item, index) in formValidateFive.foregoerMemberList"
        >
          <Crumbs :title="'设计创新团队带头人及主要成员情况 '"></Crumbs>
          <Row>
            <Col span="24">基本情况</Col>
          </Row>
          <div class="m_item">
            <Row type="flex" class="m_row">
              <Col class="m_col m_title" span="4">姓名</Col>
              <Col class="m_col" span="8">
                <Input readonly v-model="item.peopleName" class="unit_input" />
              </Col>
              <Col class="m_col m_title" span="4">
                <span>性别</span>
              </Col>
              <Col class="m_col" span="8">
                <!-- <Input v-model="item.gender" class="unit_input" /> -->
                <RadioGroup size="large" v-model="item.gender">
                  <Radio disabled label="2">男</Radio>
                  <Radio disabled label="1">女</Radio>
                </RadioGroup>
              </Col>
            </Row>
            <Row type="flex" class="m_row">
              <Col class="m_col m_title" span="4">出生年月</Col>
              <Col class="m_col" span="8">
                <Input readonly v-model="item.dateOfBirth" class="unit_input" />
              </Col>
              <Col class="m_col m_title" span="4">
                <span>国籍</span>
              </Col>
              <Col class="m_col" span="8">
                <Input readonly v-model="item.nationality" class="unit_input" />
              </Col>
            </Row>
            <Row type="flex" class="m_row">
              <Col class="m_col m_title" span="4">公司职务</Col>
              <Col class="m_col" span="8">
                <Input readonly v-model="item.companyPost" class="unit_input" />
              </Col>
              <Col class="m_col m_title" span="4">
                <span>手机号码</span>
              </Col>
              <Col class="m_col" span="8">
                <Input readonly v-model="item.phone" class="unit_input" />
              </Col>
            </Row>
          </div>
          <!-- 职称学历 -->
          <Row>
            <Col span="24">职称学历</Col>
          </Row>
          <div class="m_item">
            <Row type="flex" class="m_row">
              <Col class="m_col m_title" span="4">专业职称</Col>
              <Col class="m_col" span="20">
                <Input readonly v-model="item.specialty" class="unit_input" />
              </Col>
            </Row>
            <Row type="flex" class="m_row">
              <Col class="m_col m_title" span="4">
                学历、学位
                <br />（毕业院校及专业）
              </Col>
              <Col class="m_col" span="20">
                <Input readonly v-model="item.education" class="unit_input" />
              </Col>
            </Row>
          </div>
          <!-- 工作经历及成绩 -->
          <div class="m_item">
            <Row type="flex" class="m_row">
              <Col class="m_col m_title" span="4">工作经历及成绩</Col>
              <Col class="m_col" span="20">
                <Input
                  readonly
                  v-model="item.workExperience"
                  type="textarea"
                  :autosize="{ minRows: 5, maxRows: 5 }"
                  placeholder="从事设计创新领域的主要工作经历和取得的成绩"
                ></Input>
              </Col>
            </Row>
          </div>
        </div>
        <!-- 工业设计团队带头人及主要成员情况 -->
      </Form>
    </div>
    <!-- 页面六 -->
    <div class="attachmentUpload GL_declareForm">
      <Crumbs :title="'附件上传 '"></Crumbs>
      <Form
        class="form"
        ref="formValidateSix"
        :model="formValidateSix"
        :label-width="0"
      >
        <div class="m_item">
          <Row
            :key="item.sfId"
            v-for="item in formValidateSix.programFiles"
            type="flex"
            class="m_row"
          >
            <Col style=" padding:8px" class="m_col m_title" span="8">
              <span class="spantext">{{ item.declareMaterialName }}</span>
            </Col>
            <Col class="s_col" span="16">
              <template v-for="(item2, index) in item.programFilesList">
                <div class="picText">
                  <p>{{ item2.fileName }}</p>
                  <div @click="preview(item2.filePath)">
                    <Icon type="ios-eye" style="color:#3385FF" size="30"></Icon>
                  </div>
                </div>
              </template>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
    <div style="text-align: center; ">
      <button @click="goBack" class="GL_cancel">返回</button>
    </div>
  </div>
</template>

<script>
import Crumbs from "../../components/crumbs";
export default {
  components: {
    Crumbs
  },
  name: "",
  props: {},
  data() {
    return {
      CheckboxArray: [],
      childrenList: [],
      forEachFiles: {},
      programId: null,
      // 区域列表
      areaList: [],
      // 所属行业列表
      tradeList: [],
      formValidate: {
        companyInformation: {
          manageAddress: null, //社会信用代码
          programId: null,
          // businessAddress: null, //企业经营地址
          // companyName: null, //企业名称
          // registeredArea: 8001, //税务登记区
          // industry: null, //所属行业
          // registeredAddress: null, //企业注册地址
          unitRegistrationTypeParentId: null //企业注册类型
          // propertyOfOwnership: null, //所有制性质
          // numberOfEmployees: null, //职工人数
          // totalAssets: null, //资产总额
          // netWorth: null, //固定资产净值
          // assetLiabilityRatio: null, //资产负债率
          // bankCreditRating: null, //银行信用等级
          // companyIsBelong: null, //企业是否属于（1：上市企业 2：高新技术企业）
          // principalName: null, //负责人姓名
          // principalPost: null, //负责人职务
          // principalContactWay: null, //负责人电话
          // applyForName: null, //联系人姓名
          // applyForPost: null, //联系人职务
          // applyForContactWy: null, //联系人电话
          // operatingRevenue: null, //营业收入
          // totalTaxOfInterest: null, //利税总额
          // totalProfit: null, //利润总额
          // exportValue: null, //出口交货值
          // inTheForeheadOne: null, //近三年投入额第一年
          // inTheForeheadTwo: null, //近三年投入额第二年
          // inTheForeheadThree: null, //近三年投入额第三年
          // growthRateOne: null, //增长率第一年
          // growthRateTwo: null, //增长率第二年
          // growthRateThree: null, //增长率第三年
          // patentLicensingCount: null, //专利授权数量
          // copyrightAuthorizationCount: null, //版权授权数量
          // remark: null, //备注
          // inventPatentMandate: null, //发明专利授权数
          // inventCopyrightMandate: null, //发明版权授权数
          // inventPatentRemark: null,
          // copyrightApplication: null, //发明专利授权数
          // copyrightAuthorization: null, //发明版权授权数
          // copyrightRemark: null,
          // companyTheConstruction: null,
          // scienceCondition: null,
          // standardConditin: null,
          // qualityTheConstruction: null,
          // projectCase: null,
          // dutyCondition: null,
          // planCondition: null
        },
        companyContacts: [
          {
            productName: null, //产品名称
            capacity: null, //产能
            previousYearOutput: null, //上年度产量
            marketOccupancy: null
          }
        ],
        resourceAndPerfInfo: {
          awardsInfoList: [
            {
              awardsWorks: null,
              awardsName: null,
              obtainTime: null,
              authorizationDepartment: null,
              programType: 1
            }
          ],
          designResultList: [
            {
              projectName: null,
              clientCompany: null,
              finishDeliveryTime: null,
              industrializationResult: null,
              programType: 1
            }
          ],
          hardwareFacilityList: [
            {
              apparatusName: null,
              numberOfUnits: null,
              cost: null,
              useInfo: null,
              programType: 1
            }
          ],
          softwareList: [
            {
              softwareName: null,
              quantity: null,
              cost: null,
              useInfo: null,
              programType: 1
            }
          ],
          publicService: [
            {
              serviceType: null,
              projectName: null,
              tame: null,
              section: null,
              programType: 1
            }
          ]
        },
        statusQuoPlan: {
          //牵头或参与完成国家、本市重大设计创新项目情况
          nationalMajorProgramInfo: null,
          //组织或参与国家、本市重要设计类活动情况
          activitySituation: null,
          //发展情况说明
          operationInfo: null,
          //中心今后三年目标与规划情况
          targetAndPlan: null,
          programType: 1
        },
        programId: null,
        pageStatus: 2,
        declareType: 3001,
        //工业设计中心情况
        industrialDesignCenter: {
          programId: null,
          designCenterName: null,
          affirmTime: null, //获得区级工业设计中心认定时间
          establishedTime: null, //成立时间
          siteArea: null,
          propertyTotal: null, //资产总额
          operationPattern: null, //运营模式(1:独立核算 2:非独立核算)
          principalName: null,
          principalPost: null,
          principalPhone: null,
          contactName: null,
          contactPost: null,
          contactPhone: null, //联系人电话
          businessCount: null, //工业设计从业人数
          educationDuty: null, //其中：本科及以上学历人员数（含具有技师及以上职业资格人员、高级专业技术职务人员）和占比
          educationDutyCount: null, //其中：本科及以上学历人员数（含具有技师及以上职业资格人员、高级专业技术职务人员）
          technicianCount: null, //具有技师（⾼级技师）职业资格的⼈员、⾼级专业技术职务⼈员数
          technicianProportion: null //具有技师（⾼级技师）职业资格的⼈员、⾼级专业技术职务⼈员数及占⽐
        },
        inputInfoList: [
          //投入情况、运行情况
          {
            inputTotal: null, //投入总额
            expenseProportion: null, //占企业设计研发支出比重
            expenditureExpend: null, //其中：设计人员经费支出
            // centerExpenditureExpend: null, //中心运营经费支出
            // groomCost: null, //其中：培训费用
            // serviceOutsourcing: null, //工业设计服务外包额
            assumeProjectCount: null, //承担工业设计项目数
            finishProjectCount: null, //其中：完成项目数
            industrializationProjectCount: null, //产业化项目数
            // intellectualPropertyCount: null, //拥有自主知识产权成果数
            // industrializationResultCount: null, //其中：产业化成果数
            patentLicensingCount: null, //专利授权数
            inventPatentCount: null, //其中:发明专利数
            // copyrightAuthorization: null, //版权授权数
            designStandardCount: null, //设计标准制定数
            nationalStandardCount: null, //国家标准
            industryStandardCount: null, //行业标准
            localStandardCount: null, //地方标准
            year: 2022 //年份
          },
          {
            inputTotal: null,
            expenseProportion: null,
            expenditureExpend: null,
            // centerExpenditureExpend: null,
            // groomCost: null,
            // serviceOutsourcing: null,
            assumeProjectCount: null,
            finishProjectCount: null,
            industrializationProjectCount: null,
            // intellectualPropertyCount: null,
            // industrializationResultCount: null,
            patentLicensingCount: null,
            inventPatentCount: null,
            // copyrightAuthorization: null,
            designStandardCount: null,
            year: 2023
          },
          {
            inputTotal: null,
            expenseProportion: null,
            expenditureExpend: null,
            // centerExpenditureExpend: null,
            // groomCost: null,
            // serviceOutsourcing: null,
            assumeProjectCount: null,
            finishProjectCount: null,
            industrializationProjectCount: null,
            // intellectualPropertyCount: null,
            // industrializationResultCount: null,
            patentLicensingCount: null,
            inventPatentCount: null,
            // copyrightAuthorization: null,
            designStandardCount: null,
            year: 2024
          }
        ]
      },
      //   页面五
      formValidateFive: {
        programId: null,
        pageStatus: 5,
        declareType: 3001,
        foregoerMemberList: [
          {
            peopleName: null,
            gender: null,
            dateOfBirth: null,
            nationality: null,
            companyPost: null,
            phone: null,
            specialty: null,
            education: null,
            workExperience: null,
            programType: 1
          },
          {
            peopleName: null,
            gender: null,
            dateOfBirth: null,
            nationality: null,
            companyPost: null,
            phone: null,
            specialty: null,
            education: null,
            workExperience: null,
            programType: 1
          }
        ]
      },
      //   页面六
      formValidateSix: {
        programId: null,
        pageStatus: 6,
        declareType: 3001,
        programFiles: []
      }
    };
  },
  created() {
    this.getRradeList();
    this.pageOne();
    this.PageTwo();
    this.pageFive();
    this.pageSix();
    this.initAttachment();
    // 获取区域列表
    this.getAreaList();
  },
  mounted() {},
  methods: {
    /*获取行业列表
     *method getRradeList
     */
    async getRradeList() {
      let { result } = await this.$http.get(
        "shsj_declare/sysDict/queryDictDataList",
        {
          dictId: 4000
        }
      );
      this.tradeList = result.dictDataList;
    },
    /*获取区域列表
     *method getAreaList
     */
    async getAreaList() {
      let { result } = await this.$http.get(
        "shsj_declare/sysDict/queryDictDataList",
        {
          dictId: 8000
        }
      );
      this.areaList = result.dictDataList;
    },
    /*初始化专项附件
     *method  initAttachment
     **/
    async initAttachment() {
      var res = await this.$http.post("shsj_declare/sysDict/getSpecialFile", {
        specialId: this.$route.query.specialId,
        declareType: 3001
      });
      if (res.messageCode == 10000) {
        this.specialFiles = res.result.specialFiles;
      }
    },
    /*在没有id的情况下获取项目id
     *method queryPage
     */
    /*初始化数据
     *method queryPage
     */
    async pageOne() {
      let res = await this.$http.post(
        "shsj_declare/industralDesign/getIndustralDesignInfo",
        {
          programId: this.$route.query.programId,
          pageStatus: 1,
          declareType: 3001
        }
      );
      if (res.messageCode === 10000) {
        if (res.result.result != null) {
          this.formValidate.companyInformation =
            res.result.result.companyInformation;
          if (this.formValidate.companyInformation.companyName == null) {
            this.formValidate.companyInformation.companyName =
              localStorage.companyName;
          }

          this.formValidate.companyContacts =
            res.result.result.companyInformation.companyContacts;
          this.formValidate.companyInformation.unitRegistrationTypeParentId =
            this.formValidate.companyInformation.unitRegistrationTypeParentId -
            0;
          this.formValidate.companyInformation.allNature =
            this.formValidate.companyInformation.allNature - 0;
          this.formValidate.companyType = res.result.result.companyType;
          let index = this.formValidate.companyType.findIndex(
            item =>
              item.dictId ==
              this.formValidate.companyInformation.unitRegistrationTypeParentId
          );
          if (index == -1) {
            this.childrenList = [];
          } else {
            this.childrenList = this.formValidate.companyType[
              index
            ].sysDictDataList;
          }
          // 多选框
          if (this.formValidate.companyInformation.companyIsBelong == 1) {
            this.CheckboxArray = [1];
          } else if (
            this.formValidate.companyInformation.companyIsBelong == 2
          ) {
            this.CheckboxArray = [2];
          } else if (
            this.formValidate.companyInformation.companyIsBelong == 3
          ) {
            this.CheckboxArray = [1, 2];
          } else if (
            this.formValidate.companyInformation.companyIsBelong == 4
          ) {
            this.CheckboxArray = [4];
          } else if (
            this.formValidate.companyInformation.companyIsBelong == 5
          ) {
            this.CheckboxArray = [1, 4];
          } else if (
            this.formValidate.companyInformation.companyIsBelong == 6
          ) {
            this.CheckboxArray = [2, 4];
          } else if (
            this.formValidate.companyInformation.companyIsBelong == 7
          ) {
            this.CheckboxArray = [1, 2, 4];
          }
        }
      }
    },
    async PageTwo() {
      let res = await this.$http.post(
        "shsj_declare/industralDesign/getIndustralDesignInfo",
        {
          programId: this.$route.query.programId,
          pageStatus: 2,
          declareType: 3001
        }
      );
      if (res.messageCode === 10000) {
        if (res.result.result.idcInfo != null) {
          this.formValidate.industrialDesignCenter = res.result.result.idcInfo;
          if (res.result.result.idcInfo.inputInfoList.length == 3) {
            this.formValidate.inputInfoList =
              res.result.result.idcInfo.inputInfoList;
          }
        }
        if (res.result.result.resourceAndPerfInfo != null) {
          this.formValidate.resourceAndPerfInfo =
            res.result.result.resourceAndPerfInfo;
        }
        if (res.result.result.plan != null) {
          this.formValidate.statusQuoPlan = res.result.result.plan;
        }
      }
    },
    async pageFive() {
      let res = await this.$http.post(
        "shsj_declare/industralDesign/getIndustralDesignInfo",
        {
          programId: this.$route.query.programId,
          pageStatus: 5,
          declareType: 3001
        }
      );
      if (res.messageCode === 10000) {
        if (res.result.result != null && res.result.result.length > 0) {
          this.formValidateFive.foregoerMemberList = res.result.result;
        }
      }
    },
    async pageSix() {
      let res = await this.$http.post(
        "shsj_declare/industralDesign/getIndustralDesignInfo",
        {
          programId: this.$route.query.programId,
          pageStatus: 6,
          declareType: 3001,
          specialId: this.$route.query.specialId
        }
      );
      if (res.messageCode === 10000) {
        if (res.result.result != null) {
          this.formValidateSix.programFiles = res.result.result;
        }
      }
    },
    /** 返回
     * method goBack
     * */
    goBack() {
      this.$router.go(-1);
    },
    /*method
     **点击预览
     */

    preview(path) {
      window.open(path);
    }
  }
};
</script>

<style lang="scss" scoped>
.radiolist {
  width: 100px;
  margin-right: 4px;
  margin-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.personnel {
  .forItem {
    position: relative;

    .iconBox {
      // width: 50px;
      position: absolute;
      left: 310px;
      top: 9px;
      // right: 0;
      z-index: 888;

      .icon {
        padding: 0px 5px;
        color: #3385ff;
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
}

.attachmentUpload {
  .spantext {
    max-width: 100%;
    display: inline-block;
    overflow-wrap: break-word;
  }

  .m_item {
    .s_col {
      border-right: 1px solid #e8ecf5;
      border-bottom: 1px solid #e8ecf5;

      .ivu-btn {
        margin: 5px;
        border-radius: 20px;
        padding: 10px 50px;
      }

      .picText {
        padding: 10px;
        display: inline-block;
      }
    }
  }
}
</style>
